<template>
  <div class="login" v-loading="loading" element-loading-text="登陆中，请稍后"
       element-loading-background="rgba(255, 255, 255, 0.8)">
    <canvas id="canvas"></canvas>
    <div class="login-title">
      <div class="title-text">捷信云物联网大数据服务平台</div>
    </div>
    <transition name="el-fade-in-linear">
      <div class="login-box" v-show="show">
        <div class="login-form">
          <div class="form">
            <div class="login-pic">
              <div class="picBox">
                <img src="../assets/img/login-pic.png" alt="">
              </div>
            </div>
            <div class="form-box">
              <div class="title">用户登录</div>
              <div class="form-content">
                <el-form :model="loginForm" status-icon :rules="rules" ref="loginForm" class="demo-ruleForm">
                  <el-form-item prop="username">
                    <el-input v-model="loginForm.username" placeholder="请输入您的用户名" autocomplete="off"
                              prefix-icon="el-icon-user" clearable></el-input>
                  </el-form-item>
                  <el-form-item prop="password">
                    <el-input type="password" v-model="loginForm.password" @keyup.enter.native="login"
                              placeholder="请输入您的密码" autocomplete="off" prefix-icon="el-icon-lock"
                              show-password></el-input>
                  </el-form-item>
                  <el-form-item prop="code">
                    <el-row :span="24">
                      <el-col :span="12">
                        <el-input v-model="loginForm.code" auto-complete="off" placeholder="请输入验证码"
                                  prefix-icon="el-icon-warning-outline" @keyup.enter.native="login"></el-input>
                      </el-col>
                      <el-col :span="12">
                        <div class="login-code" @click="refreshCode">
                          <!--验证码组件-->
                          <s-identify :identifyCode="identifyCode"></s-identify>
                        </div>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="login">登 陆</el-button>
                  </el-form-item>
                </el-form>
              </div>
              <!-- <div class="login-qrCode">
                <span>APP下载：</span>
                <img src="https://xsw-xf.oss-cn-hangzhou.aliyuncs.com/xiaofang/erm.png" alt="" @click="showQrCode">
              </div> -->
            </div>
            <div class="login-num">
              <img src="../assets/img/login-num.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- 二维码 -->
    <div class="login-qrCode">
      <div class="qrCode">
        <img src="https://xsw-xf.oss-cn-hangzhou.aliyuncs.com/xiaofang/微信公众号二维码.jpg" alt="" @click="showQrCode(1)">
        <span>服务号</span>
      </div>
      <div class="qrCode">
        <img
          src="https://xsw-xf.oss-cn-hangzhou.aliyuncs.com/xiaofang/69__1fbcba4c623add1bccfa2809cacfaaf2_c87368cfaa7fcff65cf77a57ec0c27ea.png"
          alt="" @click="showQrCode(2)">
        <span>捷信云</span>
      </div>
    </div>
    <!-- 图标 -->
    <div class="company-logo">
      <img src="../assets/img/logo.png" alt="" width="70px" height="80px">
    </div>
    <!-- 二维码 -->
    <el-dialog class="dialog" :visible.sync="qrCodeShow" width="350px">
      <img :src="qrCodeImg" alt="" width="300px" height="300px">
    </el-dialog>
    <!-- 所属 -->
    <div class="company-info">
      <span>江西捷点科技有限公司</span>
      <span>售后电话：0796-8105119</span>
      <span>公司地址：江西省吉安市吉州区北门街道沿江路锦绣文山129号</span>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import SIdentify from '../components/SIdentify.vue'

export default {
  components: {
    SIdentify
  },
  data() {
    const validateCode = (rule, value, callback) => {
      if (this.identifyCode !== value) {
        this.loginForm.code = ''
        this.refreshCode()
        callback(new Error('请输入正确的验证码'))
      }
      else {
        callback()
      }
    }
    return {
      qrCodeShow: false,
      show: false,
      loginForm: {},
      loading: false,
      ctx: null,
      stars: [],
      hue: 217,
      w: 0,
      h: 0,
      identifyCode: '',
      identifyCodes: '1234567890',
      qrCodeImg: '',
      rules: {
        username: [
          {required: true, message: '请输入您的用户名', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入您的密码', trigger: 'blur'}
        ],
        code: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
          {validator: validateCode, trigger: 'blur'}
        ]
      }
    }
  },
  mounted() {
    this.initStar()
    this.refreshCode()
    setTimeout(() => {
      this.show = true
    }, 500)
  },
  methods: {
    // 登录
    login: function () {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          const data = {
            username: this.loginForm.username.trim(),
            password: this.loginForm.password.trim()
          }
          this.loading = true
          try {
            const {data: result} = await this.$axios.post('/user/login', data)
            sessionStorage.setItem('roleName', result.data.roleName)
            sessionStorage.setItem('userId', result.data.user.id)
            sessionStorage.setItem('username', result.data.user.username)
            sessionStorage.setItem('userLng', result.data.user.centerLng)
            sessionStorage.setItem('userLat', result.data.user.centerLat)
            sessionStorage.setItem('areaCode', result.data.user.areaCode)
            sessionStorage.setItem('roleId', result.data.user.roleId)
            sessionStorage.setItem('ogranizedId', result.data.user.ogranizedId)
            sessionStorage.setItem('isAdmin', result.data.user.type)
            sessionStorage.setItem('areaName', result.data.user.areaName)
            sessionStorage.setItem('token', result.data.token)

            // 获取菜单
            const {data: result2} = await this.$axios.post('/user/findMenu')
            const menu = result2.data
            sessionStorage.setItem('menu', JSON.stringify(menu))
            this.loading = false

            const menuItem = _.find(menu, function (params) {
              return Object.prototype.hasOwnProperty.call(params, 'path')
            })
            this.$message({
              message: '登陆成功',
              type: 'success',
              duration: 1500
            })
            this.$router.push(menuItem.path)
          } finally {
            this.loading = false
          }
          // const { data: result } = await this.$axios.post('/user/login', data)
          // if (result.code === 200) {
          //   sessionStorage.setItem('roleName', result.data.roleName)
          //   sessionStorage.setItem('userId', result.data.user.id)
          //   sessionStorage.setItem('username', result.data.user.username)
          //   sessionStorage.setItem('userLng', result.data.user.centerLng)
          //   sessionStorage.setItem('userLat', result.data.user.centerLat)
          //   sessionStorage.setItem('areaCode', result.data.user.areaCode)
          //   sessionStorage.setItem('roleId', result.data.user.roleId)
          //   sessionStorage.setItem('ogranizedId', result.data.user.ogranizedId)
          //   sessionStorage.setItem('isAdmin', result.data.user.type)
          //   sessionStorage.setItem('areaName', result.data.user.areaName)
          //   localStorage.setItem('token', result.data.token)
          //
          //   // 获取菜单
          //   const { data: result2 } = await this.$axios.post('/user/findMenu')
          //   // if (result2.code === 200) {
          //   //   const menu = result2.data
          //   //   sessionStorage.setItem('menu', JSON.stringify(menu))
          //   //   this.loading = false
          //   //
          //   //   const menuItem = _.find(menu, function (params) {
          //   //     return Object.prototype.hasOwnProperty.call(params, 'path')
          //   //   })
          //   //   this.$message({
          //   //     message: '登陆成功',
          //   //     type: 'success',
          //   //     duration: 1500
          //   //   })
          //   //   this.$router.push(menuItem.path)
          //   // }
          // } else {
          //   this.$message.error(result.msg)
          //   this.loading = false
          // }
        }
      })
    },
    showQrCode(flag) {
      switch (flag) {
        case 1:
          this.qrCodeImg = 'https://xsw-xf.oss-cn-hangzhou.aliyuncs.com/xiaofang/微信公众号二维码.jpg'
          this.qrCodeShow = true
          break
        case 2:
          this.qrCodeImg = 'https://xsw-xf.oss-cn-hangzhou.aliyuncs.com/xiaofang/69__1fbcba4c623add1bccfa2809cacfaaf2_c87368cfaa7fcff65cf77a57ec0c27ea.png'
          this.qrCodeShow = true
          break
      }
    },
    initStar() {
      const self = this
      const canvas = document.querySelector('#canvas')
      this.ctx = canvas.getContext('2d')
      this.w = canvas.width = window.innerWidth
      this.h = canvas.height = window.innerHeight
      const maxStars = 1200

      const canvas2 = document.createElement('canvas')
      const ctx2 = canvas2.getContext('2d')
      canvas2.width = 100
      canvas2.height = 100
      const half = canvas2.width / 2
      const gradient2 = ctx2.createRadialGradient(half, half, 0, half, half, half)
      gradient2.addColorStop(0.025, '#fff')
      gradient2.addColorStop(0.1, 'hsl(' + this.hue + ', 61%, 33%)')
      gradient2.addColorStop(0.25, 'hsl(' + this.hue + ', 64%, 6%)')
      gradient2.addColorStop(1, 'transparent')

      ctx2.fillStyle = gradient2
      ctx2.beginPath()
      ctx2.arc(half, half, half, 0, Math.PI * 2)
      ctx2.fill()

      const Star = function () {
        this.orbitRadius = self.random(self.maxOrbit(self.w, self.h))
        this.radius = self.random(60, this.orbitRadius) / 12
        this.orbitX = self.w / 2
        this.orbitY = self.h / 2
        this.timePassed = self.random(0, maxStars)
        this.speed = self.random(this.orbitRadius) / 2500000
        this.alpha = self.random(2, 10) / 10
      }

      Star.prototype.draw = function () {
        const x = Math.sin(this.timePassed) * this.orbitRadius + this.orbitX
        const y = Math.cos(this.timePassed) * this.orbitRadius + this.orbitY
        const twinkle = self.random(35)

        if (twinkle === 1 && this.alpha > 0) {
          this.alpha -= 0.05
        }
        else if (twinkle === 2 && this.alpha < 1) {
          this.alpha += 0.05
        }

        self.ctx.globalAlpha = this.alpha
        self.ctx.drawImage(canvas2, x - this.radius / 2, y - this.radius / 2, this.radius, this.radius)
        this.timePassed += this.speed
      }

      for (var i = 0; i < maxStars; i++) {
        this.stars[i] = new Star()
      }

      this.startAnimation()
    },
    random(min, max) {
      if (arguments.length < 2) {
        max = min
        min = 0
      }
      if (min > max) {
        const hold = max
        max = min
        min = hold
      }
      return Math.floor(Math.random() * (max - min + 1)) + min
    },
    maxOrbit(x, y) {
      const max = Math.max(x, y)
      const diameter = Math.round(Math.sqrt(max * max + max * max))
      return diameter / 2
    },
    startAnimation() {
      this.ctx.globalCompositeOperation = 'source-over'
      this.ctx.globalAlpha = 0.8
      this.ctx.fillStyle = 'hsla(' + this.hue + ', 80%, 20%, 1)'
      this.ctx.fillRect(0, 0, this.w, this.h)

      this.ctx.globalCompositeOperation = 'lighter'
      for (var i = 1, l = this.stars.length; i < l; i++) {
        this.stars[i].draw()
      }
      window.requestAnimationFrame(this.startAnimation)
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    refreshCode() {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
          ]
      }
    }
  }
}
</script>

<style lang="scss">
.dialog {
  margin: 0;
  padding: 10px;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    .tip {
      text-align: center;
      color: #303133;
      margin-bottom: 15px;
      font-size: 18px;
      font-weight: 600;
    }
  }
}

.login {
  height: 100vh;
  width: 100%;
  margin: 0;
  background: #0a285b;
  position: relative;
  overflow: hidden;

  .login-title {
    position: absolute;
    top: 5%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .title-text {
      color: #fff;
      font-size: 36px;
      font-weight: 600;
      letter-spacing: 5px;
      margin-left: 10px;
      text-shadow: 4px 6px 6px #14182d;
    }
  }

  .login-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;

    .login-form {
      width: 70%;
      height: 60%;
      margin: 0 auto;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;

      .form {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 50px;
        box-sizing: border-box;
        position: relative;

        .login-pic {
          width: 50%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .picBox {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
              width: 90%;
              height: 100%;
              z-index: 2;
            }
          }
        }

        .form-box {
          width: 50%;
          height: 100%;
          z-index: 2;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .title {
            font-size: 32px;
            color: #fff;
            margin-bottom: 2rem;
          }

          .form-content {
            width: 100%;
            display: flex;
            justify-content: center;

            .el-form {
              width: 55%;

              .el-form-item {
                margin-bottom: 30px;

                .el-input__inner {
                  background: transparent;
                  border-radius: 7px;
                  border-color: #9ce7ff;
                  color: #fff;
                }

                .el-input__icon {
                  color: #00deff;
                }

                .el-button {
                  width: 100%;
                  background-color: rgba(85, 110, 183, 0.2);
                  border-radius: 7px;
                  border: none;
                  margin-top: 10px;
                  color: #fff;
                }
              }
            }
          }

          // .login-qrCode {
          //   width: 100%;
          //   span {
          //     font-size: 14px;
          //     color: #fff;
          //     vertical-align: middle;
          //   }
          //   img {
          //     width: 60px;
          //     height: 60px;
          //     vertical-align: middle;
          //     cursor: pointer;
          //   }
          // }
        }

        .login-num {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 50%;
          height: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          img {
            width: 60%;
            height: 70%;
          }
        }
      }
    }
  }

  .company-logo {
    position: absolute;
    left: 20px;
    top: 20px;
  }

  .company-info {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    color: #fff;

    span:first-child {
      margin-right: 10px;
    }

    span:last-child {
      margin-left: 10px;
    }
  }

  .login-qrCode {
    position: absolute;
    right: 20px;
    top: 20px;
    display: flex;

    .qrCode {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 10px;

      span {
        font-size: 14px;
        color: #fff;
        margin-top: 10px;
      }

      img {
        width: 60px;
        height: 60px;
        cursor: pointer;
      }
    }
  }
}
</style>
